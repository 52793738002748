@font-face {
  font-family: "Helvetica";
  src: url('./assets/fonts/AkayaKanadaka-Regular.ttf');
}

* {
  font-family: Helvetica !important;
}


body {
  margin: 0;
  background-color: #e8e8e8;
}

a {
  text-decoration: none;
  color: inherit;
}