.show-card-detail {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 8px;
    /* opacity: 0.2; */
    background: rgba(255, 255, 255, 0.8);
}

.project-card:hover .show-card-detail {
    visibility: visible;
}


.menu-item-mobile {
    transition: 0.3s ease;
}


.menu-item-mobile:hover {
    background-color: #ff4500;
    color: #ffffff;
    border-radius: 4px;
    padding-left: 32px;

}